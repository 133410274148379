// import Config from 'Config';

import _ from 'lodash';
import Popper from 'popper.js';
import jQuery from 'jquery';
import 'bootstrap';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { pusher as pusherConfig } from '@/config';

window._ = _;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = Popper;
    window.$ = window.jQuery = jQuery;
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.axios = require('axios');
// window.axios.defaults.baseURL = Config.sub_dir;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// window.axios.defaults.headers.common['frontend'] = 'sd8f7aihr';

axios.defaults.baseURL = Config.sub_dir;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['frontend'] = 'sd8f7aihr';
window.axios = axios;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.pusher = Pusher;

window.pusherClass = new Pusher(pusherConfig.key, {
    cluster: pusherConfig.cluster,
    forceTLS: true,
});

window.echo = new Echo({
    authEndpoint: Config.sub_dir + '/broadcasting/auth',
    broadcaster: 'pusher',
    key: pusherConfig.key,
    cluster: pusherConfig.cluster,
    // forceTLS: true
});
