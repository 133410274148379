import moment from 'moment';
import React, { useRef, useState } from 'react';
import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
    DateRangePickerInput,
} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export default function DateInput({ clear = true, ...props }) {
    const [focusedInput, setFocused] = useState(false);

    return (
        <div className="date-range-input">
            <DateRangePicker
                startDate={props.dateFrom} // momentPropTypes.momentObj or null,
                endDate={props.dateTo} // momentPropTypes.momentObj or null,
                onDatesChange={({ startDate, endDate }) => {
                    props.handleDateFrom(startDate);
                    props.handleDateTo(endDate);
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => setFocused(focusedInput)} // PropTypes.func.isRequired,
                // initialVisibleMonth={() => moment().add(2, 'M')} // PropTypes.func or null,
                numberOfMonths={1}
                showClearDates={clear}
                // format to dd/mm/yyyy
                displayFormat={() => 'DD/MM/YYYY'}
            />
        </div>
    );
}
