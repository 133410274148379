import { useState, useEffect } from 'react';
import axios from 'axios';
import useSWR from 'swr';

const fetcher = (url) => axios.get(url).then((res) => res.data);

export default function useCountryCodes() {
    const { data, error } = useSWR(route('country-codes'), fetcher, null);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}
